import React from "react"
import * as styles from "./ITPlatform.module.css"
import cx from "classnames"
import BannerImg from "../../assets/images/it_platform/banner.png"
import BusinessIcon from "../../assets/images/it_platform/business_round.svg"
import UsersGroupIcon from "../../assets/images/it_platform/users_group.svg"
import ProgrammaticIcon from "../../assets/images/services_icons/programmatic.svg"

const ITPlatform = () => {
  return (
    <div className={cx(styles.container, "container")}>
      <header>
        <div className={styles.headerInfo}>
          <div className={styles.infoContainer}>
            <h3>DV Platform</h3>
            <h5>
              собственная CDP (Customer Data Platform), позволяющая собирать,
              анализировать и сегментировать данные онлайн-транзакций и MAID.
            </h5>
          </div>
          <div className={styles.buttonGetBox}>
            <div>
              <a
                target="_blank"
                download
                rel="noreferrer"
                href="https://cdp-ui.dvgroup.com/docs/%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F_%D0%BF%D0%BE_%D1%83%D1%81%D1%82%D0%B0%D0%BD%D0%BE%D0%B2%D0%BA%D0%B5_%D0%B8_%D1%8D%D0%BA%D1%81%D0%BF%D0%BB%D1%83%D0%B0%D1%82%D0%B0%D1%86%D0%B8%D0%B8_%D0%9F%D0%9E.pdf "
                className={cx("theme-button", styles.buttonGetAccess)}
              >
                Руководство пользователя
              </a>
            </div>
            <p className={styles.buttonGetItemText}>
              DVPlatform - это SAAS решение, доступ к платформе осуществляется
              по сети интернет, специальные действия по установке ПО на стороне
              пользователя не требуются
            </p>
          </div>
        </div>

        <div className={styles.image}>
          <img src={BannerImg} alt="banner" />
        </div>
      </header>

      <section className={styles.advantages}>
        <ul>
          <li>
            <div className={styles.advantagesIcon}>
              <img src={BusinessIcon} alt="Advantages Business Icon" />
            </div>
            <p>
              Данная технология помогает проводить рекламные кампании на любые
              кастомные сегменты покупателей категории, конкурентов, брендов или
              конкретных товаров, а также строить Sales Lift по используемому
              сегменту.
            </p>
          </li>

          <li>
            <div className={styles.advantagesIcon}>
              <img src={UsersGroupIcon} alt="Advantages Business Icon" />
            </div>
            <p>
              В&nbsp;платформе собрано более 50&nbsp;млн уникальных
              пользователей и&nbsp;250 готовых вариантов таксономии.
              Анонимизированные данные попадают в&nbsp;систему от&nbsp;наших
              партнёров&nbsp;&mdash; ПБД, Атол, Платформа ОФД и&nbsp;другие.
              IT-решение признано лучшей CDP 2021&nbsp;года, имеет патенты
              на&nbsp;самописные предиктивные модели.
            </p>
          </li>

          <li>
            <div className={styles.advantagesIcon}>
              <img src={ProgrammaticIcon} alt="Advantages Business Icon" />
            </div>
            <p>
              DV&nbsp;Programmatic&nbsp;&mdash; собственная DSP (Demand-Side
              Platform), позволяющая закупать трафик в&nbsp;режиме реального
              времени на&nbsp;своём инвентаре в&nbsp;декстопе и&nbsp;мобайле.
              Отличительной особенностью нашего DSP является интеграция
              с&nbsp;ПБД, позволяющая таргетироваться не&nbsp;только
              на&nbsp;аудиторию по&nbsp;интересам,
              но&nbsp;и&nbsp;на&nbsp;онлайн- покупателей категорий, брендов
              и&nbsp;товаров.
            </p>
          </li>
        </ul>

        <p className={styles.fromOctober}>
          С октября 2024 года доступно как Self Service для рекламных агентств.
        </p>
      </section>
    </div>
  )
}

export default ITPlatform
